<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1" data-uk-grid>
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3">
              <span data-uk-icon="users"></span> Kunden verwalten
            </h3>
          </div>
          <div>
            <router-link :to="{name: 'tenant-edit'}" class="uk-button uk-button-primary">
              <span uk-icon="plus-circle"></span>
              Neu
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid uk-grid-small" data-uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small uk-margin-large-bottom">
          <div class="uk-card-body">
            <table class="uk-table uk-table-hover uk-table-divider" v-if="tenants.length">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>E-Mail</th>
                  <th>Telefon</th>
                  <th class="uk-table-small">Kunde seit</th>
                  <th class="uk-width-small"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="uk-visible-toggle" v-for="tenant in tenants" :key="tenant.id">
                  <td>
                    <router-link
                      :to="{name: 'tenant-edit', params: {id: tenant.id }}"
                    >
                      {{ tenant.profile.company }}
                    </router-link>
                  </td>
                  <td>
                    {{ tenant.profile.email }}
                  </td>
                  <td>
                    {{ tenant.profile.phone }}
                  </td>
                  <td>
                    {{ new Intl.DateTimeFormat('de-DE').format(new Date(tenant.created_at)) }}
                  </td>
                  <td>
                    <div class="uk-width-small uk-text-right uk-margin-small-right">
                      <a href="#" class="uk-icon-link" uk-icon="more"></a>
                      <div uk-dropdown="mode: click;animation: uk-animation-slide-top-small;offset: 5;pos:bottom-right">
                        <ul class="uk-nav uk-dropdown-nav  uk-text-left">
                          <li>
                            <router-link
                              :to="{name: 'tenant-edit', params: {'id': tenant.id }}"
                            >
                              Bearbeiten
                            </router-link>
                          </li>
                          <li>
                            <a class="uk-text-danger uk-icon" href="#" @click.prevent="remove(tenant.id)">
                              Löschen
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
     </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      checked: [],
      allSelected: false
    }
  },
  mounted () {
    this.$store.dispatch('getTenants')
  },
  computed: {
    ...mapGetters(['tenants'])
  },
  methods: {
    remove () {

    }
  }
}
</script>
